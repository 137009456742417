const YELLOW = "#FFFF00";
const GREEN = "#00FF00";
const RED = "#FF0000";
const BROWN = "#964B00";
const LILA = "#800080";
const BLUE = "#1E90FF";
const WHITE = "#ffffff";
const GRAY = "#808080";
const LIGHT_GRAY = "#D3D3D3";

const developmentTasks = [
    {
        clusterId: 1,
        name: "Sozio-emotionale Entwicklung",
        tiles: [
            {
                tileId: 1,
                name: "Urvertrauen",
                ageRange: [0, 12],
                lowerBoundaries: {
                    done: 13,
                    actual: 4.5,
                    started: 0.5
                },
                items: [1, 2, 3, 4, 5, 6, 7, 25, 26, 27]
            },
            {
                tileId: 2,
                name: "Grundlagen des\nSelbstwertgefühls",
                ageRange: [12, 18],
                lowerBoundaries: {
                    done: 5,
                    actual: 2,
                    started: 0.5
                },
                items: [8, 9, 10, 28]
            },
            {
                tileId: 3,
                name: "Symbiose-Autonomie-Konflikt",
                ageRange: [18, 30],
                lowerBoundaries: {
                    done: 11,
                    actual: 4,
                    started: 0.5
                },
                items: [11, 12, 13, 29, 30, 31, 32, 33]
            },
            {
                tileId: 4,
                name: "Grundlagen der emotionalen\nKonstanz und des konstruktiven\nSozialverhaltens",
                ageRange: [30, 48],
                lowerBoundaries: {
                    done: 6.5,
                    actual: 2.5,
                    started: 0.5
                },
                items: [14, 15, 16, 17, 34, 35, 36, 37]
            },
            {
                tileId: 5,
                name: "Erwerb der Gruppenfähigkeit",
                ageRange: [48, 72],
                lowerBoundaries: {
                    done: 8.5,
                    actual: 3,
                    started: 0.5
                },
                items: [18, 19, 20, 38, 39, 40, 41, 42, 43]
            },
            {
                tileId: 6,
                name: "Eigenständige Gruppenfähigkeit\nund Leistungsbereitschaft",
                ageRange: [72, 144],
                lowerBoundaries: {
                    done: 4,
                    actual: 1.5,
                    started: 0.5
                },
                items: [21, 22, 44, 45, 46]
            },
            {
                tileId: 7,
                name: "Identitätssuche",
                ageRange: [144, 216],
                lowerBoundaries: {
                    done: 5,
                    actual: 2,
                    started: 0.5
                },
                items: [23, 47, 48, 49]
            },
            {
                tileId: 8,
                name: "Selbstverantwortete\nLebensführung",
                ageRange: [216, 1188],
                lowerBoundaries: {
                    done: 2,
                    actual: 1,
                    started: 0.5
                },
                items: [24, 50]
            }
        ]
    },
    {
        clusterId: 2,
        name: "Kognitive Entwicklung",
        tiles: [
            {
                tileId: 1,
                name: "Sinnlich - motorische\nGrundlagen des Denkens",
                ageRange: [0, 18],
                lowerBoundaries: {
                    done: 9.5,
                    actual: 3.5,
                    started: 0.5
                },
                items: [51, 52, 53, 54, 55, 56, 57, 79, 80, 92, 93, 94]
            },
            {
                tileId: 2,
                name: "Beginn des vorstellungs-\ngeleiteten Denkens",
                ageRange: [18, 24],
                lowerBoundaries: {
                    done: 4,
                    actual: 1.5,
                    started: 0.5
                },
                items: [58, 59, 60, 81, 95]
            },
            {
                tileId: 3,
                name: "Symbolisches Denken:\nGebrauch der primären\n Denkformen",
                ageRange: [24, 48],
                lowerBoundaries: {
                    done: 16,
                    actual: 5.5,
                    started: 0.5
                },
                items: [61, 62, 63, 64, 65, 66, 82, 83, 84, 85, 86, 87, 96, 97]
            },
            {
                tileId: 4,
                name: "Anschauungsgebundenes Denken:\nÜbergang von den primären\nzu den rationalen Denkformen",
                ageRange: [48, 72],
                lowerBoundaries: {
                    done: 11,
                    actual: 4,
                    started: 0.5
                },
                items: [67, 68, 69, 70, 71, 72, 88, 89, 90, 92, 98, 99]
            },
            {
                tileId: 5,
                name: "Konkret-logisches Denken:\nGebrauch der rationalen\nDenkformen",
                ageRange: [72, 144],
                lowerBoundaries: {
                    done: 5,
                    actual: 2,
                    started: 0.5
                },
                items: [73, 74, 75, 76, 77, 100]
            },
            {
                tileId: 6,
                name: "Formal-logisches Denken:\nGebrauch der abstrakten\nDenkformen",
                ageRange: [72, 144],
                lowerBoundaries: {
                    done: 1,
                    actual: 0.5,
                },
                items: [78]
            },
        ]
    }
]


export default class DevelopmentTasksCanvas {

    constructor(canvas, items, forExport) {
        this.ctx = canvas.getContext('2d');
        this.forExport = forExport;

        this.items = items;
        this.developmentTasks = developmentTasks;

        this.draw = this.draw.bind(this);
    }

    draw() {
        const items = this.items;
        const ctx = this.ctx;
        const developmentTasks = this.developmentTasks;

        ctx.clearRect(0, 0, this.calculateCanvasWidth(), this.calculateCanvasHeight());
        ctx.fillStyle = WHITE;
        ctx.fillRect(0, 0, this.calculateCanvasWidth(), this.calculateCanvasHeight());

        const ageRanges = this.getAllAgeRanges(developmentTasks);
        this.drawAgeRanges(ctx, ageRanges);

        let i = 0;
        for (const task of developmentTasks) {
            // const categories = this.getItemCategories(items);

            const clusterTasks = this.getDevelopmentTaskCluster(developmentTasks, task.clusterId);
            //const clusterItems = this.getDevelopmentTaskClusterItems(items, task.clusterId)

            this.drawDevelopmentTaskClusterBorders(ctx, clusterTasks, this.getNormalizedAges(this.getAllAgeRanges(developmentTasks)).length - 1, i);
            this.drawDevelopmentTask(ctx, clusterTasks.tiles, ageRanges, items, i);

            i++;
        }
        // let i = 0;
        // categories.forEach(element => {
        //     this.drawCategoryBorders(ctx, this.getItemsByCategory(items, element), this.getAgeRanges(items), i);
        //     this.drawCategory(ctx, items, this.getItemsByCategory(items, element), this.getAgeRanges(items), i);

        //     i++;
        // });

        this.drawLegend(ctx);
    }

    getDevelopmentTaskClusterItems(items, clusterId) {
        let clusterItems = [];
        for (const element of items) {
            if (element.itemDevelopmentTask.clusterId === clusterId) {
                clusterItems.push(element);
            }
        }
        return clusterItems;
    }

    getItemScoreFromSuccessor(item, items) {
        let score = 0;

        items.forEach(successor => {
            if (successor.itemId === item.itemSuccessor) {
                if (successor.itemValue === "1" || successor.itemValue === "2a" || successor.itemValue === "2b" || successor.itemValue === "2c") {
                    if (item.itemType === "f") {
                        score = 2;
                    } else if (item.itemType === "i") {
                        score = 1;
                    }

                } else {
                    score = this.getItemScoreFromSuccessor(successor, items);
                }
            }
        });
        return score;
    }

    calculateTileScore(tileItems) {
        let score = 0;
        for (const element of tileItems) {
            if (element.itemValue === "1") {
                score = score + 1;
            } else if (element.itemValue === "2a") {
                score = score + 0.5;
            } else if (element.itemValue === "2b") {
                    score = score + 0.5;
            } else if (element.itemValue === "2c") {
                score = score + 0.5;
            } else if (element.itemValue === "3") {
                if (element.itemType === "f" || element.itemType === "i") {
                    score = score + this.getItemScoreFromSuccessor(element, this.items);
                }
            }
        }
        return score;
    }

    getDevelopmentTaskCluster(developmentTasks, clusterId) {
        for (const task of developmentTasks) {
            if (task.clusterId === clusterId) {
                return task;
            }
        }
    }

    getItemsByClusterTile(items, clusterId, tileId) {
        let clusterTileItems = [];

        for (const element of items) {
            if (element.itemDevelopmentTask.clusterId === clusterId && element.itemDevelopmentTask.tileId === tileId) {
                clusterTileItems.push(element);
            }
        }
        return clusterTileItems;
    }

    getAgeRanges(developmentTasks, clusterId) {
        let ageRanges = [];

        for (const task of developmentTasks) {
            if (task.clusterId === clusterId) {

                for (const tile of task.tiles) {
                    ageRanges.push(tile.ageRange);
                }
            }
        }

        //sort array by the age range beginning
        //a and b are two items from the age range array
        ageRanges.sort((a, b) => a[0] - b[0]);

        return ageRanges;
    }

    getAllAgeRanges(developmentTasks) {
        let ageRanges = [];

        for (const task of developmentTasks) {
            ageRanges.push.apply(ageRanges, this.getAgeRanges(developmentTasks, task.clusterId));
        }

        return ageRanges;
    }

    getNormalizedAges(ageRanges) {
        let ages = []
        ageRanges.forEach(element => {
            if (!ages.includes(element[0])) {
                ages.push(element[0])
            }
            if (!ages.includes(element[1])) {
                ages.push(element[1])
            }
        });

        ages.sort((a, b) => a - b);

        return ages;
    }

    drawLegend(ctx) {
        let canvasHeight = this.calculateCanvasHeight();
        let posy = canvasHeight - this.getLegendHeight() + (20 * this.getDevicePixelRatio());


        ctx.fillStyle = "#000000";
        ctx.font = this.getHeadlineFont();
        ctx.textAlign = "left";
        ctx.textBaseline = "top";

        ctx.fillText("Auseinandersetzung mit der Entwicklungsaufgabe: ", this.getYWidth(), posy);
        ctx.font = this.getStandardFont();

        posy = posy + this.getHeadlineFontHeight() + (6 * this.getDevicePixelRatio());
        ctx.fillText("gelb - hinreichend bewältigt", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

        ctx.fillStyle = YELLOW;
        ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
        ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());

        ctx.fillStyle = "#000000";
        posy = posy + this.getStandardFontHeight() + (2 * this.getDevicePixelRatio());
        ctx.fillText("rot: aktuell", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

        ctx.fillStyle = RED;
        ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
        ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());

        ctx.fillStyle = "#000000";
        posy = posy + this.getStandardFontHeight() + (2 * this.getDevicePixelRatio());
        ctx.fillText("grün: beginnt", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

        ctx.fillStyle = GREEN;
        ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
        ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());


        ctx.fillStyle = "#000000";
        posy = posy + this.getStandardFontHeight() + (2 * this.getDevicePixelRatio());
        ctx.fillText("weiß: nicht erreicht", this.getYWidth() + this.getStandardFontHeight() + (3 * this.getDevicePixelRatio()), posy);

        ctx.fillStyle = WHITE;
        ctx.fillRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
        ctx.strokeRect(this.getYWidth(), posy, this.getStandardFontHeight(), this.getStandardFontHeight());
    }

    drawAgeRanges(ctx, ageRanges) {
        let canvasHeight = this.calculateCanvasHeight() - this.getLegendHeight();
        let posY = canvasHeight;

        ctx.fillStyle = "#000000";
        ctx.font = this.getHeadlineFont();
        ctx.textAlign = "right";
        ctx.textBaseline = "top";

        const textPosX = this.getYWidth() - (10 * this.getDevicePixelRatio());
        const linePosX = this.getYWidth();

        //draw axis line
        ctx.beginPath();
        ctx.moveTo(linePosX, posY);
        ctx.lineTo(linePosX, this.getTopBorder());
        ctx.lineWidth = this.getLineWidth();
        ctx.stroke();

        //draw age range texts
        ctx.font = this.getHeadlineFont();

        posY = posY - this.getXHeight();

        let ages = this.getNormalizedAges(ageRanges);

        ages.forEach(age => {
            //draw ticks
            ctx.beginPath();
            ctx.moveTo(linePosX, posY);
            ctx.lineTo(textPosX, posY);
            ctx.lineWidth = this.getLineWidth();
            ctx.stroke();

            ctx.textBaseline = "middle";

            if (age > 36) {
                ctx.fillText((age / 12) + " J ", textPosX, posY);
            } else {
                ctx.fillText(age + " M ", textPosX, posY);
            }
            posY = posY - this.getAgeRangeHeight();
        });

        //draw top tick
        ctx.beginPath();
        ctx.moveTo(linePosX, posY);
        ctx.lineTo(textPosX, posY);
        ctx.lineWidth = this.getLineWidth();
        ctx.stroke();

    }

    getDevelopmentTaskClusterTileItems(task, items) {
        let tileItems = [];

        // tileItems = task.items.map((x) => items[x - 1]);

        tileItems = items.filter(item => task.items.some(taskItem => item.itemId === taskItem));

        return tileItems;

    }

    drawDevelopmentTask(ctx, clusterTasks, ageRanges, items, taskPosition) {

        let ages = this.getNormalizedAges(ageRanges);

        let posX = this.getYWidth() + this.getClusterWidth() * taskPosition;

        //start from the bottom
        let posY = this.calculateCanvasHeight(ages.length) - this.getXHeight() - this.getLegendHeight();

        for (const task of clusterTasks) {

            let taskAgeRangeHeight = ages.indexOf(task.ageRange[1]) - ages.indexOf(task.ageRange[0]);

            let rowHeight = this.getAgeRangeHeight() * taskAgeRangeHeight;
            let columnWidth = this.getClusterWidth();

            let tileItems = this.getDevelopmentTaskClusterTileItems(task, items);

            let taskScore = this.calculateTileScore(tileItems)
            console.log("Task: [" + taskPosition + ", " + task.tileId + "], Score: " + taskScore);

            ctx.fillStyle = this.getTaskColor(task, taskScore);
            ctx.fillRect(posX, posY - rowHeight, columnWidth, rowHeight);

            ctx.strokeRect(posX, posY - rowHeight, columnWidth, rowHeight);
            this.drawTaskText(ctx, task, posX + (columnWidth / 2), posY - (rowHeight / 2));


            posY = posY - this.getAgeRangeHeight() * taskAgeRangeHeight;
        }


        // canvasAgeRanges.forEach(element => {
        //     let ageRangeItems = this.getItemsByAgeRange(items, element);

        //     let rowHeight, columnWidth;

        //     //one column layout
        //     rowHeight = this.getAgeRangeHeight() / ageRangeItems.length;
        //     columnWidth = this.getCategoryWidth();

        //     for (let i = 0; i < ageRangeItems.length; i++) {

        //         ctx.fillStyle = this.getItemColor(ageRangeItems[i], allItems);
        //         ctx.fillRect(posX, posY - (i * rowHeight) - rowHeight, columnWidth, rowHeight);

        //         if (ageRangeItems[i].itemType === "f") {
        //             if (ageRangeItems[i].itemValue === "1" || ageRangeItems[i].itemValue === "2a" || ageRangeItems[i].itemValue === "2b" || ageRangeItems[i].itemValue === "2c") {
        //                 ctx.fillStyle = RED;
        //                 ctx.fillRect(posX, posY - (i * rowHeight) - rowHeight, (columnWidth / 5), rowHeight);
        //             }
        //         }

        //         ctx.strokeRect(posX, posY - (i * rowHeight) - rowHeight, columnWidth, rowHeight);
        //         this.drawItemText(ctx, ageRangeItems[i], posX + (columnWidth / 2), posY - (i * rowHeight) - (rowHeight / 2));

        //     }

        //     posY = posY - this.getAgeRangeHeight();
        // });
    }

    drawTaskText(ctx, task, posX, posY) {
        let text = task.name;
        let lineHeight = this.getStandardFontHeight() + 2;

        ctx.font = this.getStandardFont();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = '#000000';

        let lines = text.split('\n');

        for (let i = 0; i < lines.length; i++)
            ctx.fillText(lines[i], posX, posY + (i * lineHeight) - (lineHeight/2) * (lines.length - 1));


        // ctx.fillText(text, posX, posY);
    }


    drawDevelopmentTaskClusterBorders(ctx, cluster, canvasAgeRanges, taskPosition) {
        let posX = this.getYWidth() + this.getClusterWidth() * taskPosition;
        let posY = this.getTopBorder();


        for (let i = 1; i <= canvasAgeRanges; i++ ) {
            ctx.fillStyle = GRAY;
            ctx.fillRect(posX, posY, this.getClusterWidth(), this.getAgeRangeHeight());
            ctx.strokeRect(posX, posY, this.getClusterWidth(), this.getAgeRangeHeight());

            posY = posY + this.getAgeRangeHeight();
        }

        //draw right tick
        ctx.beginPath();
        ctx.moveTo(posX + this.getClusterWidth(), posY);
        ctx.lineTo(posX + this.getClusterWidth(), posY + this.getXHeight());
        ctx.lineWidth = this.getLineWidth();
        ctx.stroke();

        ctx.font = this.getHeadlineFont();
        ctx.textAlign = "center";
        ctx.textBaseline = "top";

        let textPosX = posX + (this.getClusterWidth() / 2)

        let textPosY = posY + (5 * this.getDevicePixelRatio());
        ctx.fillStyle = '#000000';
        ctx.fillText(cluster.name, textPosX, textPosY);
    }

    //returns height of X-Axis
    getXHeight() {
        return 20 * this.getDevicePixelRatio();
    }

    //returns width of Y-Axis
    getYWidth() {
        return 60 * this.getDevicePixelRatio();
    }

    getAgeRangeHeight() {
        return 60 * this.getDevicePixelRatio();
    }

    getClusterWidth() {
        return 220 * this.getDevicePixelRatio();
    }

    getDevicePixelRatio() {
        if (!this.forExport) {
            return window.devicePixelRatio;
        } else {
            return 1.2;
        }
    }

    getHeadlineFont() {
        let size = this.getHeadlineFontHeight();
        return "bold " + size + "px Calibri";
    }

    getHeadlineFontHeight() {
        return this.getDevicePixelRatio() * 14;
    }

    getStandardFont() {
        let size = this.getStandardFontHeight()
        return size + "px Calibri";
    }

    getStandardFontHeight() {
        return this.getDevicePixelRatio() * 14;
    }

    getLineWidth() {
        return 1 * this.getDevicePixelRatio();
    }

    getTopBorder() {
        return 20 * this.getDevicePixelRatio();
    }

    getLegendHeight() {
        return (34 * this.getDevicePixelRatio()) + this.getHeadlineFontHeight() + (this.getStandardFontHeight() * 4);
    }

    getLegendWidth() {
        return 350 * this.getDevicePixelRatio();
    }

    calculateCanvasHeight() {
        const ageRangesCount = this.getNormalizedAges(this.getAllAgeRanges(developmentTasks)).length - 1;
        return (ageRangesCount * this.getAgeRangeHeight()) + this.getXHeight() + this.getTopBorder() + this.getLegendHeight();
    }

    calculateCanvasWidth() {
        const clusterCount = developmentTasks.length;
        const canvasWidth = (clusterCount * this.getClusterWidth()) + this.getYWidth();
        if (canvasWidth > this.getLegendWidth()) {
            return canvasWidth;
        } else {
            return this.getLegendWidth();
        }
    }

    getTaskColor(task, score) {

        if (score >= task.lowerBoundaries.done) {
            return YELLOW;
        }

        if (score >= task.lowerBoundaries.actual) {
            return RED;
        }

        if (score >= task.lowerBoundaries.started) {
            return GREEN;
        }

        return WHITE;
    }

}